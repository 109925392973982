import * as React from 'react';
import { useRef, useState , useContext} from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from "prop-types";
import PushPinIcon from '@mui/icons-material/PushPin';
import IosShareIcon from '@mui/icons-material/IosShare';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
// import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
// import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import CardElementMenu from './CardElementMenu';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Evaluate from './Evaulation';
import AppContext from "../store/AppContext";
import FALLBACK_IMAGE from '../assets/images/test.jpg';
import unable_to_retrieve_image from "../assets/images/unable_to_retrieve_image.png";

import { ArticleOutlined } from '@mui/icons-material';
const resource="article";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function MyCardElement({ index, article, handleRemove, show}) {
  const [expanded, setExpanded] = React.useState(false);
  const [isLoggedIn, user] = useContext(AppContext);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [isHovering, setIsHovering] = useState(false);
  const articleRef = useRef();
  
  const onMediaFallback = (event) => {
    event.target.src = unable_to_retrieve_image;
  };
  return (

    <div
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      
    >
   
    <Card sx={{ maxWidth: 200 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[300] }} aria-label="{resource}">
            {article.publisher.slice(0,1).toUpperCase()}
          </Avatar>
        }
        // action={
        //   <CardElementMenu />
        // }
        titleTypographyProps={{variant:'h6' }}
        title ={article.publisher}
        subheader = {article.published_date}
        //subheader="September 14, 2016"
       // subheader = {article.publisher}
        //subheader = {article.published_date}
      />
      <CardMedia
        component="img"
        //src={`https://corsproxy.io/?${article.image_url}`}
    
        src={article.image_url}
        alt="image not available"
        ref={articleRef}
        onClick={(event)=>{ window.open(article.url, '_blank', 'noreferrer');}}
        width="100%"
        height="auto"
        onError={onMediaFallback}
        // crossOrigin="anonymous"
      />
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent> */}
       
      {isLoggedIn && (
        <CardActions disableSpacing>
        <IconButton aria-label="thumbs up">
          <ThumbUpOffAltIcon  />
        </IconButton>
        <IconButton aria-label="thumbs down">
          <ThumbDownOffAltIcon />
        </IconButton>

        <IconButton aria-label="love it">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="comment">
          <CommentIcon />
        </IconButton>
        <IconButton aria-label="tagIt">
          <TipsAndUpdatesIcon />
        </IconButton>
        {/* TipsAndUpdatesIc
        TipsAndUpdatesIcon */}
        {/* <IconButton aria-label="get a qr code">
          <NoteAddIcon />
        </IconButton> */}
      

        {/* <IconButton aria-label="get a qr code">
        </IconButton>
          <QrCodeIcon /> */}
       {/* <Evaluate  /> */}
       </CardActions>)
       }
        {/* <IconButton aria-label="settings">
            <PushPinIcon />
          </IconButton>
          <IconButton aria-label="settings">
            <IosShareIcon />
          </IconButton>
          <IconButton aria-label="settings">
            <ShareIcon />
          </IconButton>
          <IconButton aria-label="settings">
            <LoyaltyIcon />
          </IconButton> */}
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
  
      <Collapse in={true} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography paragraph>{article.url}</Typography> */}
          <Typography variant="h6" onClick={(event)=>{ window.open(article.url, '_blank', 'noreferrer');}}>
           {article.title}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>

    </div>
  );
}
MyCardElement.propTypes = {
  show: PropTypes.func,
  index: PropTypes.number,
  image: PropTypes.string,
  handleRemove: PropTypes.func,
};
export default MyCardElement;