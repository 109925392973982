import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import firebase from "../config/firebaseConfig";

// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
//import FirebaseSocial from './authentication/auth-forms/FirebaseSocial';
import AnimateButton from "../layout/elements/AnimateButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthLogin = () => {
  const [checked, setChecked] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().min(6).max(255).required("Password is required")
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (isLoading) return;
        setIsLoading(true);
        setStatus({ success: false });

        //getAuth(firebaseApp);
        firebase.auth().signInWithEmailAndPassword(values.email, values.password)
          .then((res) => {
            history.replace("/");
            setIsLoading(false);
            setSubmitting(false);
          })
          .catch((e) => {
            setStatus(e.message);
            setErrors({
              submit:
                "Encounter invalid account email or password. Please verify and try again. Thank you!"
            });
            setIsLoading(false);
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email-login">Email Address</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-login">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  id="password-login"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton
                  //       aria-label="toggle password visibility"
                  //       onClick={handleClickShowPassword}
                  //       onMouseDown={handleMouseDownPassword}
                  //       edge="end"
                  //       size="large"
                  //     >
                  //       {!showPassword ? (
                  //         <VisibilityOutlinedIcon />
                  //       ) : (
                  //         <VisibilityOffOutlinedIcon />
                  //       )}
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                  placeholder="Enter password"
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-login"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            {errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      name="checked"
                      color="primary"
                      size="small"
                    />
                  }
                  label={<Typography variant="h6">Keep me sign in</Typography>}
                />
                <Link
                  variant="h6"
                    component={RouterLink}
                  to="/reset"
                  color="primary"
                  // color="text.primary"
                >
                  Forgot Password?
                </Link>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </AnimateButton>
            </Grid>
            {/* <Grid item xs={12}>
                                <FirebaseSocial />
                            </Grid> */}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default AuthLogin;
