import React, { useContext } from "react";
import { useHistory, NavLink } from "react-router-dom";
import firebase from "../config/firebase";
import AppContext from "../store/AppContext";
import SearchContext from "../store/SearchContext";
import SearchChannelContext from "../store/SearchChannelContext";
import FixNewSearch from "./FixNewSearch";
import Box from '@mui/material/Box';


import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import logo from '../assets/images/aidemi.png';

import Email from "./Email.js";
//import logo from '../assets/images/aidemi.png';
export default function Header({showMenu}) {
  const [isLoggedIn, user] = useContext(AppContext);
  const {searchTerm, setSearchTerm}= useContext(SearchContext);
  const {searchChannel, setSearchChannel}= useContext(SearchChannelContext);
 // const {searchChannelTerm, setSearchChannelTerm}= useContext(SearchChannelContext);
  const location = useLocation();
  const history = useHistory();

  let isRequestFromArticles = (location.pathname.endsWith('articles\/')||location.pathname.endsWith('articles'));
  function logout() {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        history.replace("/login");
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  }

  return (

    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="py-2 bg-gray-900 text-white flex justify-between" position="static">
        <Toolbar>
        <Link href="/">
        <Box
        className="pb-2"
        component="img"
        sx={{ height: 38}}
        alt="Aidemi News Portal"
        //src={"/static/media/aidemi.6fe991b3.png"}
       // src={{logo}.replace(/^\./,"")}
        src={logo}
         />
     
        </Link>
          {/* <nav className="py-2 bg-gray-900 text-white flex justify-between text-xl font-bold">
            <NavLink to="/" exact activeClassName="underline text-blue-200">
              AIDEMI
            </NavLink>
          </nav> */}
          {/* <Box className="py-2 w-full px-10 bg-gray-900 text-white flex justify-between">
            <FixNewSearch />
          </Box> */}
          <Box className="py-2 w-full px-10 bg-gray-900 text-white flex justify-center text-base">

         {/* {!showMenu && (<nav className="py-2 bg-gray-900 text-white flex justify-between"> */}
         { (<nav className="py-2 bg-gray-900 text-white flex justify-between">
          
              <ul className="flex justify-between px-10">
                <li className="mx-5">
                <NavLink to="/articles/politics" 
                 activeClassName="text-blue-200"
                 onClick={(e)=>{
                  setSearchChannel("Politics"); 
                 // e.preventDefault();
                 }}
                   >
                    Politics
                </NavLink>
                </li>
                <li className="mx-5">
                <NavLink to="/articles/business" 
                activeClassName="text-blue-200"
                 onClick={(e)=>{
                  setSearchChannel("BusinessFinance"); 
                  //e.preventDefault();
                 }}
                    >
                     Business & Finance
                </NavLink>
                </li>
                <li className="mx-5">
                 <NavLink to="/articles/science" activeClassName="text-blue-200"
                  onClick={(e)=>{
                    setSearchChannel("ScienceTechnology"); 
                 // e.preventDefault();
                 }}
                 >
                     Science & Inovations
                </NavLink>
               </li>
               <li className="mx-5">
                 <NavLink to="/articles/entertainment" onClick={(e)=>{
                 setSearchChannel("Entertainment"); 
                 // e.preventDefault();
                 }}
                 isActive={ () => { 
       
                  if(!searchChannel && isRequestFromArticles){
                    setSearchChannel("Entertainment"); 
                   return true; }
                    else
                    {if(searchChannel==="Entertainment"){ return true;}}
                }
                }
                activeClassName="text-blue-200">
                     Entertainment & Sports
                 </NavLink>
               </li>
               <li className="mx-5">
                 <NavLink to="/articles/lifestyle"  
                  onClick={(e)=>{
                    setSearchChannel("Lifestyle"); 
                 // e.preventDefault();
                 }}
                 activeClassName="text-blue-200">
                     Lifestyle
                  </NavLink>
               </li>
               {/* <li className="mx-5">
                 <NavLink to="/articles/travel" onClick={(e)=>{
                 setSearchChannel("Travel"); 
                 // e.preventDefault();
                 }}
                 activeClassName="text-blue-200">
                     Travel
                </NavLink>
               </li> */}
               <li className="mx-5">
                 <NavLink to="/articles/world" onClick={(e)=>{
                 setSearchChannel("WorldNews"); 
                 // e.preventDefault();
                 }}
                 activeClassName="text-blue-200">
                     World News
                </NavLink>
               </li>
              </ul>
              </nav>
              )}
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />
                   <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <nav className="py-2 bg-gray-900 text-white flex justify-between">
              <ul className="flex justify-left px-10">
                  <li className="ml-5">
                 
                    {/* <Email label="Contact Us" mailto="mailto: feedback@aidemi.com"/> */}
                     <Link href="https://forms.gle/5P64oMoiiseHwcxW6" target="_blank" className="py-2 bg-gray-900 text-white flex justify-between">
                      Contact Us
                    </Link>
                  </li>
              </ul>
            </nav>
          </Box>
          {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <nav className="py-2 bg-gray-900 text-white flex justify-between">
              <ul className="flex justify-between px-10">
                <li className="mx-5">
                  {isLoggedIn ? (
                    <button onClick={logout}>Logout</button>
                  ) : (
                    <NavLink to="/login" activeClassName="underline text-blue-200">
                      Login
                    </NavLink>
                  )}
                </li>
                {!isLoggedIn && (
                  <li className="ml-5">
                    <NavLink to="/signup" activeClassName="underline text-blue-200">
                      SignUp
                    </NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </Box> */}
        </Toolbar>
      </AppBar>
    </Box>
  );

}
