import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SettingsPhoneTwoTone } from '@mui/icons-material';

export default function ModelDialog({ closeDialogHandler, contentInfo }) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closeDialogHandler();
    //setOpen(false);
    //reSetPin(false);

  };
  const youtybe = "https://www.youtube.com/embed/b4i7j6U7Ul8";
  const image = "https://www.google.com/imgae";
  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {contentInfo.active.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              <iframe
                title="x"
                src={contentInfo.active.link}
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-same-origin allow-presentation"
                loading='lazy'>

              </iframe>
      
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}