import React, { useState, useContext} from "react";
import CardElements from "../components/ReadCardElements";
import Header from "../components/Header";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SearchChannelContext from "../store/SearchChannelContext";

export default function ReadGallery(props) {
   
    const theme = useTheme();
    const {searchChannel, setSearchChannel} = useContext(SearchChannelContext);
   // const [checked, setChecked] = React.useState(false);
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [showAdPanel, setShowAdPanel] = useState(true);
    const [location, setLocation] = useState(useLocation());
    let searchCategory  =  "";
  
    if(!searchChannel){
        
        let pathElements =  location.pathname.split("/");
        let category = pathElements[pathElements.length-1].toUpperCase();

        switch(category){
            case "Politics".toUpperCase(): searchCategory="Politics"; break;
            case "Tech".toUpperCase(): searchCategory="ScienceTechnology";break;
            case "Sports".toUpperCase(): searchCategory="Entertainment";break;
            case "Business".toUpperCase(): searchCategory="BusinessFinance";break;
            case "Finance".toUpperCase(): searchCategory="BusinessFinance";break;
            case "Entertainment".toUpperCase(): searchCategory="Entertainment";break;
            case "Health".toUpperCase(): searchCategory="Lifestyle";break;
            case "Weather".toUpperCase(): searchCategory="Travel";break;
            case "Lifestyle".toUpperCase(): searchCategory="Lifestyle";break;
            case "Auto".toUpperCase(): searchCategory="Entertainment";break;
            case "Science".toUpperCase(): searchCategory="ScienceTechnology";break;
           // case "Travel".toUpperCase(): searchCategory="Travel";break;
            case "Environment".toUpperCase(): searchCategory="Lifestyle";break;
            case "World".toUpperCase(): searchCategory="WorldNews"; break;
            default:   searchCategory="";
        }
        setSearchChannel(searchCategory);
        
    }
   
    console.log(searchChannel);
    return (
        <>
            <div>
                <div className="text-center">
                    <Header />
                </div>
                {
                    showAnnouncement && (
                        <Box className="p-4 rounded bg-yellow-500 text-black flex justify-between m-3 h2" sx={{ width: '95%', fontSize: "1rem" }}
                        >
                            Welcome to Aidemi News & Information Portal. We are so excited to give you an early glance at our site. More are to come, including your personal portal to help you manage your information so stay tuned!
                            Send us your feedback with Contact Us link above. Looking forward to your feedback!
                            {/* {searchChannel} */}
                            <IconButton onClick={() => {
                                setShowAnnouncement(!showAnnouncement);
                            }}>{theme.direction !== 'rtl' ? <CloseIcon /> : <CloseIcon />}</IconButton>
                        </Box>
                    )

                }

                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

                    <Box className="bg-rose-300" sx={{ flexDirection: 'column',  height: '100%' }}>
                        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', height: '100%' }}>
                            <Box className="mx-1" sx={{ width:'100%' }}>
                                <CardElements />
                            </Box>
                            {/* {!showAdPanel && (<Box className="bg-rose-200" sx={{ flexGrow: 1, width:'14%' }} >
                                
                            <IconButton onClick={() => {
                                setShowAdPanel(!showAdPanel);
                            }}>{theme.direction !== 'rtl' ? <CloseIcon /> : <CloseIcon />}</IconButton>
                            </Box>)} */}
                        </Box>
                    </Box>

                </Box>


            </div>

        </>
    );
}
