import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from "../../page/Home";
import Gallery from "../../page/Gallery";
//import Login from "../../page/Login";
//import SignUp from "../../page/SignUp";
import Tensorflow from "../../page/tensorflow";
import Drawerable from "../../page/Drawerable";
import ReadGallery from '../../page/ReadGallery';
import SignIn from '../../page/SignIn';
import SignUp from '../../page/SignUp';
import RestPassword from '../../page/ResetPasswrod';
import Intro from '../../page/Intro';
//import { Route } from "@mui/icons-material";
export default [
  {
    path: "/home",
    exact: true,
    component: () => <Home />,
    protected: "auth",
  },
  {
    path: "/",
    exact: true,
    component: () => <Intro />,
    protected: "null",
  },
  {
    path: "/gallery",
    component: () => <Gallery />,
    protected: "null",
  },
  {
    path: "/login",
    component: () => <SignIn />,
    protected: "guest",
  },
  {
    path: "/signup",
    component: () => <SignUp />,
    protected: "guest",
  },
  {
    path: "/reset",
    component: () => <RestPassword />,
    protected: "guest",
  },
  {
    path: "/articles",
    exact: false,
    component: () => <ReadGallery />,
    protected: "null"
  
  },
  // {
  //   path: "/login2",
  //   component: () => <Login2 />,
  //   protected: "guest",
  // },
  // {
  //   path: "/Register",
  //   component: () => <AuthRegister />,
  //   protected: "guest",
  // },
  // {
  //   path: "/login3",
  //   component: () => <NewLogin />,
  //   protected: "guest",
  // },
  // {
  //   path: "/signup",
  //   component: () => <SignUp />,
  //   protected: "guest",
  // },
  // {
  //   path: "/tensorflow",
  //   component: () => <Tensorflow />,
  //   protected: null,
  // },
  // {
  //   path: "/admin",
  //   component: () => <Drawerable />,
  //   protected: "auth",
  // },
  // {
  //   path: "/gallery2",
  //   component: () => <GalleryWithDrawable />,
  //   protected: null,
  // },
];
