import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyCZFzR7fNGaToMjE85ANG6q6ZmwZrG-hJQ",
  authDomain: "visual-explorer-dev.firebaseapp.com",
  projectId: "visual-explorer-dev",
  storageBucket: "visual-explorer-dev.appspot.com",
  messagingSenderId: "1005360881488",
  appId: "1:1005360881488:web:7d50528e509b8a9a205655",
  measurementId: "G-DRXELNSZVB"
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
//const auth = firebase.getAuth(app);
export default firebase;
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyCZFzR7fNGaToMjE85ANG6q6ZmwZrG-hJQ",
//   authDomain: "visual-explorer-dev.firebaseapp.com",
//   projectId: "visual-explorer-dev",
//   storageBucket: "visual-explorer-dev.appspot.com",
//   messagingSenderId: "1005360881488",
//   appId: "1:1005360881488:web:7d50528e509b8a9a205655",
//   measurementId: "G-DRXELNSZVB"
// };

// Initialize Firebase
////const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
