import React, { useState, useEffect, useContext ,useInterval} from "react";
import CardElement from "./ReadCardElement";
import useFetchArticles from "../utils/hooks/useFetchNews";
import Loading from "./Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import AppContext from "../store/AppContext";
import SearchContext from "../store/SearchContext";
import SearchChannelContext from "../store/SearchChannelContext";
import no_image_url_provided from "../assets/images/no_image_url_provided.png";
const preProcessArticle=(article)=>{
  if(!article.image_url || !article.image_url.replace( / /g,"")){
    article.image_url=no_image_url_provided;
  }
  return article;
}


export default function Images() {
  const {searchChannel, setSearchChannel} = useContext(SearchChannelContext);
  const [page, setPage] = useState(1);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  //const [searchChannel, setSearchChannel] = useState(searchChannel);
  const [articles, setArticles, errors, isLoading] = useFetchArticles(
    page,
    searchChannel,
    setSearchChannel,
    setPage
  );

  // useEffect(() => {
  //   setSearchChannel(searchChannel?searchChannel:null);
  //  }, [page]);
 

  function handleRemove(index) {
    setArticles([
      ...articles.slice(0, index),
      ...articles.slice(index + 1, articles.length),
    ]);
  }
  // if(articles.length===0){
  //   setPage(page + 1);
  // }
  return (
    <section>
      {errors.length > 0 && (
        <div className="flex h-screen">
          <p className="m-auto">{errors[0]}</p>
        </div>
      )}
      <AnimateSharedLayout>
        <InfiniteScroll
          dataLength={articles.length}
          next={() => 
            setPage(page + 1)
          }
          hasMore={true}
          className="flex flex-wrap"
        >
          {articles.map((article, index) =>{ 
            return (
            <motion.div
              className="2xl:w-1/5 px-12 py-5 flex justify-center "
              key={index}
              layoutId={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <CardElement
                show={() => {
                  setShowPreview(true);
                  setSelectedIndex(index);
                }}
                article={ preProcessArticle(article)}
                handleRemove={handleRemove}
                index={index}
              />
            </motion.div>
          )}
          )}
        </InfiniteScroll>

        <AnimatePresence>
          {showPreview && (
            <motion.section
              layoutId={selectedIndex}
              exit={{ opacity: 0, rotate: 180, transition: { duration: 1 } }}
              className="fixed w-full h-full flex justify-center items-center top-0 left-0 z-40"
              onClick={() => {setShowPreview(false)}}
            >
              <div className="bg-white">
                <CardElement
                  article={articles[selectedIndex]}
                  className="rounded-lg"
                  width="200"
                  height="auto"
                />
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </AnimateSharedLayout>
      {isLoading && <Loading />}
    </section>
  );
}
