import React, { useState, useContext} from "react";
import { Typography } from "@mui/material";
import Header from "../components/Header";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModelDialog from '../components/ModelDialog';
import { styled, useTheme } from '@mui/material/styles';

const image =process.env.PUBLIC_URL+"/newsracks.jpg";
  const links2 = ["Terms", "Privacy", "Cookies"];
  const currentYear = new Date().getFullYear();
const contentInfo = {
  active:"terms",
  terms:{
    link:"https://www.google.com/imgage",
    title:'Use Aidemi News and Information Portal'
  },
  privacy:{
    link:"http://locahost:3000/articles/politcs",
    title:"Aidemi News and Information Portal POlicy"
  },
  cookies:
  {link:"http://locahost:3000/articles/lifestyle",
    title:"Aidemi News and Information Portal Cookies"
  },

}

export function Intro() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const theme = useTheme();

  const closeDialog=()=>{
    setOpenDialog(false);
  }
  return (
    <>
    <section className="py-0 lg:py-0 px-0 ">
    <Header/>
        {
          showAnnouncement && (
            <Box className="p-4 rounded bg-yellow-500 text-black flex justify-between m-3 h2" sx={{ width: '95%', fontSize: "1rem" }}
            >
              Welcome to Aidemi & Information Portal. We are so excited to give you an early glance at our site. More are to come, including your personal portal to help you manage your information so stay tuned!
              Send us your feedback with Contact Us link above. Looking forward to your feedback!
              {/* {searchTerm} */}
              <IconButton onClick={() => {
                setShowAnnouncement(!showAnnouncement);
              }}>{theme.direction !== 'rtl' ? <CloseIcon /> : <CloseIcon />}</IconButton>
            </Box>
          )

        }
    </section>
    <section className="py-16 lg:py-28 px-8 bg-red-250">
      <div className="container mx-auto grid lg:grid-cols-2 items-center">
        <div className="mt-12 lg:mt-0 lg:pr-12 lg:row-auto row-start-2">
          <Typography color="blue" className="font-semibold mb-2 " sx={{ fontSize: "1.3rem"}}>
            Our Vision
          </Typography>
          <Typography variant="h2" color="blue-gray" className="mb-6">
            Be Informed
          </Typography>
          <Typography variant="lead" color="gray" className="mb-12">
          Information is important because it helps us stay informed and make informed decisions. 
          It helps us stay updated on current events and know what’s happening in the world around us. It helps us be better
          citizens and make better decisions in our own lives. Additionally, it is a source that makes our world colorful.
            <br />
            <br />
            We are a media publishing company that strives to create, share and manage 
            information that informs, educates, entertains, and inspires our readers. 
            We are committed to providing quality content and engaging experiences with the utmost integrity and respect for our readers and authors.
            <br />
            <br />
            Our vision is to provide high-quality content to our readers and create a positive impact in the world.
          </Typography>
          {/* <div className="inline-flex flex-wrap gap-3">
            <Button variant="outlined" color="blue-gray" size="lg">
              get in touch
            </Button>
            <Button size="lg">our process</Button>
          </div> */}
        </div>
        <img
          src={image}
          alt="news stands"
          className="w-full h-full rounded-xl object-cover object-center min-h-[50vh] md:min-h-[75vh] "
        />
        <div className="mt-16 py-6 border-t border-blue-gray-50 flex items-center justify-end md:justify-between gap-y-4 gap-x-8 flex-wrap">
          <Typography color="gray" className="font-normal text-center justify-center">
            &copy; {currentYear} Aidemi Media, All rights reserved.
          </Typography>

          {/* <ul className="flex items-center justify-end">
            {links2.map((link, idx) => (

              <li key={link} >
                <Typography    
                  onClick={(event)=>{
                   // setContentObj({link:{link}})
                   switch(idx){
                    case 0:  contentInfo.active=contentInfo.terms; break;
                    case 1:  contentInfo.active=contentInfo.privacy; break;
                    case 2:  contentInfo.active=contentInfo.cookies; break;
                    default:;
                   }
                   
                    setOpenDialog(true);
                   
                  }}
                  color="gray"
                  className={`py-1 font-normal hover:text-blue-gray-900 transition-colors flex items-center justify-end ${
                    idx === links2.length - 1 ? "pl-2" : "px-2"
                  }`}
                >
                  {link}
                </Typography>
              </li>
            ))}
          </ul> */}
         
        </div>
        {openDialog && <ModelDialog closeDialogHandler={closeDialog} contentInfo={contentInfo}/>}
      </div>
      
    </section>
    </>
  );
}

export default Intro;