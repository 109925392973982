import React, { useState, useEffect, useContext} from "react";
import Axios from "axios";
import searchChannelContext from "../../store/SearchChannelContext"
const api = process.env.REACT_APP_EPAX_NEWS_API_PROD;
const secret = process.env.REACT_APP_EPAX_KEY_PROD;
const environment = process.env.DATA_SRC

export default function useFetchNews(page, searchChannel,setSearchChannel,setPage) {
  const [news, setNews] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  

  console.log(api);
  function fetch() {

     if(!searchChannel){
      console.log("why searchChannel is null");
     return;
     }
     Axios.get(`${api}/api/v_1.0/news/newsdata/channels/${searchChannel}/page/${page}`)
      .then((res) => {
        // let entries = res.data.filter((entry)=>{
        //   if(!entry.image_url || entry.image_url.replace( / /g,"")){
        //     entry.image_url=NO_IMAGE;
        //   }
        //   return (entry.image_url && entry.image_url.replace( / /g,""));
        // })
      
        // if(entries.length===0){
        //   fetchRandom(entries)
        //   setPage(page+1);
        //   return;
        // }else{
          fetchRandom(res.data)
          setIsLoading(false);
        //}
      })
      .catch((e) => {
        console.log(e);
        setErrors(["Unable to fetch news"]);
        setIsLoading(false);
      });
  }

  function fetcTestData(){
    fetcTestData();
  }

  function fetchSearch(res) {
    page > 1
      ? setNews([...news,...res.data.results])
      : setNews([...res.data.results]);
  }
  //this show previous pages
  // function fetchSearch(res) {
  //   page > 1
  //     ? setImages([...images, ...res.data.results])
  //     : setImages([...res.data.results]);
  // }
  function fetchRandom(res) {
    //resultCount = resultCount+res.length;
   // if(resultCount.length<10){
    //  setNews([...news, ...res]);
   //   page++;
    //  fetch();
    //  return;
    //}
    //resultCount=0;
  
    // if(res.length===0){
    //   page++;
    //   fetch();
    //   return;
    // }
  
    setNews([...news, ...res]);
 
    
  }

  useEffect(() => {
    setIsLoading(true);
    if(environment==="LOCAL"){
      //fetcTestData();
    }else{
      fetch();
    }
   
  }, [page, searchChannel]);

  return [news, setNews, errors, isLoading];
}
