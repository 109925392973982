import React from 'react'
// import { Webcam } from '@uppy/webcam'
//import DragDrop from '@uppy/drag-drop'

//import Uppy from '@uppy/core'
//import Dashboard from '@uppy/dashboard'
//import RemoteSources from '@uppy/remote-sources'
////import ImageEditor from '@uppy/image-editor'
//import Webcam from '@uppy/webcam'
//import Tus from '@uppy/tus'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

const FileUploader = () => {
    // specify upload params and url for your files
    const getUploadParams = ({file, meta}) => { 
        console.log(meta);
        const body = new FormData()
        body.append(meta.name, file);
        body.append("uid","xdfsfasdfasdfasf");
        return { url: 'http://localhost:7001/api/loc/v_1.0/files/uploadFile', body } }
    
    // called every time a file's `status` changes
    //const handleChangeStatus = ({ meta, file }, status) => { 
    //     const handleChangeStatus = (fileWithMeta, status) => { 
    //         console.log(status);
    //         console.log(fileWithMeta) ;
    //         if(status==="done" && fileWithMeta?.xhr?.response){
    //             console.log(JSON.parse(fileWithMeta?.xhr?.response).url);

    //         }
        
    // }
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        // console.log(
        //     files.map(f => f.meta)
        // )

        console.log(JSON.parse(files[0].xhr.response).url);
     // console.log(files.map(f => f.meta))
      allFiles.forEach(f => f.remove())
      
    }
    const handleChangeStatus = (fileWithMeta, status) => {
      if (status === 'headers_received') {
       // toast(`${meta.name} uploaded!`);
               // if(status==="done" && fileWithMeta?.xhr?.response){
        //console.log(JSON.parse(xhr?.response?.url));

          //  }
        
      } else if (status === 'aborted') {
       // toast(`${meta.name}, upload failed...`)
      } else if(status === 'done'){
        console.log(fileWithMeta);
          console.log(JSON.parse(fileWithMeta.xhr.response).urls);
          fileWithMeta.remove();
      }
    }
    const toast = (innerHTML) => {
      const el = document.getElementById('toast')
      el.innerHTML = innerHTML
      el.className = 'show'
      setTimeout(() => { el.className = el.className.replace('show', '') }, 3000)
    }
    return (<>
      <div id="toast">Upload</div>
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      autoUpload={true}
        // getUploadParams={getUploadParams}
        // onChangeStatus={handleChangeStatus}
        // onSubmit={handleSubmit}
        // autoUpload={false}
        // accept="image/*,audio/*,video/*,text/*"
        inputContent="+"
        styles={{
          dropzone: { width: 40, height: 20, overflow: 'hidden', minHeight:'50px', borderStyle: 'dashed' },
          dropzoneActive: { borderColor: 'red' },
          inputLabel:{fontSize: '1rm'}
        }}
      />
      </>
    )
  }



//function Uploader (props) {
  
      



        
//   const uppy = React.useMemo(() => {


//     return new Uppy({
//         autoProceed: false,
//           restrictions: {
//           maxFileSize: 10000000, //uppy options
//           maxNumberOfFiles: 30,
//           minNumberOfFiles: 1,
//           allowedFileTypes: [".txt"]}
//     }
          
//     ).use(Dashboard, { trigger: '#select-files' })
//   //  .use(RemoteSources, { companionUrl: 'https://companion.uppy.io' })
//   //  .use(Webcam, { target: Dashboard })
//   //  .use(ImageEditor, { target: Dashboard })
//     .use(Tus, { 
//         endpoint: 'http://localhost:7001/api/loc/v_1.0/files/uploadFile',
//     //     async onBeforeRequest (req) {
//     //     const token = await getAuthToken()
//     //     req.setHeader('Authorization', `Bearer ${token}`)
//     //   },
//       onShouldRetry (err, retryAttempt, options, next) {
//         if (err?.originalResponse?.getStatus() === 401) {
//           return true
//         }
//         return next(err)
//       },
//       async onAfterResponse (req, res) {
//         if (res.getStatus() === 401) {
//           //await refreshAuthToken()
//         }
        
//       },})
//     .on('complete', (result) => {
    
//       console.log('Upload result:', result)
//     });
      
      
      

//   }, [])
//   React.useEffect(() => {
//     return () => uppy.close({ reason: 'unmount' })
//   }, [uppy])

//   const uppy = React.useMemo(() => {
//     return new Uppy()
//       .use(Webcam) // `id` defaults to "Webcam". Note: no `target` option!
//       // or
//       .use(Webcam, { id: 'MyWebcam' }) // `id` is… "MyWebcam"
//   }, [])
//   React.useEffect(() => {
//     return () => uppy.close({ reason: 'unmount' })
//   }, [uppy])

//   return (
//     <div>
//     <Dashboard //uppy dashboard component
//       uppy={uppy}
//     />
//    </div>
//   )
// }
export default FileUploader;
